import { NavBar, Hero, Knowledge, Projects, Footer } from "./containers";
import { images } from "./constants";
import React, { useEffect } from "react";
// import { Link, animateScroll as scroll } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="App font-outfit">
      <div className="flex flex-col h-screen">
        <NavBar />
        <Hero />
      </div>
      <Knowledge />
      <Projects />
      <Footer />
      {/* <Link
        to="about"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >
        <button className="fixed hidden border-2 md:fixed discover-button right-8 bottom-8">
          <img
            src={images.arrow}
            alt=""
            className="w-6 h-6 rotate-180 fill-black"
          />
        </button>
      </Link> */}
    </div>
  );
}

export default App;
