import { images } from '../../constants';
import { motion } from 'framer-motion';
import React, { useState } from 'react'
import { Link, animateScroll as scroll } from "react-scroll";

const Navbar = () => {

    const [toggle, setToggle] = useState(false);

    return (
        <div className='relative'>
            <div className='flex items-center border-b border-border-color h-90'>
                <div className='container flex justify-between w-full px-8 mx-auto md:px-20'>
                    <div className='flex items-center gap-8 md:gap-12'>
                        <h1 className='antialiased font-bold md:hidden'>S. NOGBEDJI<span className='text-dot-color'>.</span> </h1>
                        <h1 className='hidden antialiased font-bold lg:flex'>Sébastien NOGBEDJI<span className='text-dot-color'>.</span> </h1>
                        <div className='w-px h-12 bg-border-color'></div>
                        <div className='hidden gap-12 text-sm font-bold md:flex text-inactive-link'>
                            <Link to="about"
                                activeClass="active"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}>
                                <p className='text-black cursor-pointer'>Mieux me connaitre</p>
                            </Link>
                            <Link to="knowledge" activeClass="active"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}>
                                <p className='cursor-pointer'>Mes compétences</p>
                            </Link>
                            <Link to="projects" activeClass="active"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}>
                                <p className='cursor-pointer'>Projets</p>
                            </Link>
                        </div>
                    </div>
                    <a href="mailto: nssoftdev@gmail.com" className='items-center justify-center hidden rounded-full outline-none md:flex w-11 h-11 bg-contact-button'>
                        <img src={images.contact} alt="contact icon" />
                    </a>

                    <button className={toggle ? 'hidden' : 'flex md:hidden'} onClick={() => setToggle(true)}>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8334 13.9167C12.4192 13.9167 12.0834 14.2524 12.0834 14.6667C12.0834 15.0809 12.4192 15.4167 12.8334 15.4167V13.9167ZM22 15.4167C22.4143 15.4167 22.75 15.0809 22.75 14.6667C22.75 14.2524 22.4143 13.9167 22 13.9167V15.4167ZM12.8334 21.25C12.4192 21.25 12.0834 21.5858 12.0834 22C12.0834 22.4142 12.4192 22.75 12.8334 22.75V21.25ZM31.1667 22.75C31.5809 22.75 31.9167 22.4142 31.9167 22C31.9167 21.5858 31.5809 21.25 31.1667 21.25V22.75ZM22 28.5833C21.5858 28.5833 21.25 28.9191 21.25 29.3333C21.25 29.7475 21.5858 30.0833 22 30.0833V28.5833ZM31.1667 30.0833C31.5809 30.0833 31.9167 29.7475 31.9167 29.3333C31.9167 28.9191 31.5809 28.5833 31.1667 28.5833V30.0833ZM12.8334 15.4167H22V13.9167H12.8334V15.4167ZM12.8334 22.75H31.1667V21.25H12.8334V22.75ZM22 30.0833H31.1667V28.5833H22V30.0833Z" fill="#28303F"></path></svg>
                    </button>

                    <button className={!toggle ? 'hidden' : 'flex md:hidden'} onClick={() => setToggle(false)}>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8334 13.9167C12.4192 13.9167 12.0834 14.2524 12.0834 14.6667C12.0834 15.0809 12.4192 15.4167 12.8334 15.4167V13.9167ZM22 15.4167C22.4143 15.4167 22.75 15.0809 22.75 14.6667C22.75 14.2524 22.4143 13.9167 22 13.9167V15.4167ZM12.8334 21.25C12.4192 21.25 12.0834 21.5858 12.0834 22C12.0834 22.4142 12.4192 22.75 12.8334 22.75V21.25ZM31.1667 22.75C31.5809 22.75 31.9167 22.4142 31.9167 22C31.9167 21.5858 31.5809 21.25 31.1667 21.25V22.75ZM22 28.5833C21.5858 28.5833 21.25 28.9191 21.25 29.3333C21.25 29.7475 21.5858 30.0833 22 30.0833V28.5833ZM31.1667 30.0833C31.5809 30.0833 31.9167 29.7475 31.9167 29.3333C31.9167 28.9191 31.5809 28.5833 31.1667 28.5833V30.0833ZM12.8334 15.4167H22V13.9167H12.8334V15.4167ZM12.8334 22.75H31.1667V21.25H12.8334V22.75ZM22 30.0833H31.1667V28.5833H22V30.0833Z" fill="#28303F"></path></svg>
                    </button>
                </div>
            </div>
            {
                toggle && <motion.div
                    whileInView={{ y: [40, 0] }}
                    transition={{ duration: 0.65, ease: 'backIn' }}
                    className='absolute z-50 flex flex-col items-center p-10 border rounded-md shadow-2xl bg-n8 border-n6 left-5 right-5 top-32 md:hidden'
                >
                    <ul className="flex flex-col items-center gap-6 m-auto capitalize">
                        <Link to="about"
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500} onClick={() => setToggle(false)}>
                            <p className='text-black cursor-pointer'>Mieux me connaitre</p>
                        </Link>
                        <Link to="knowledge" activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500} onClick={() => setToggle(false)}>
                            <p className='cursor-pointer'>Mes compétences</p>
                        </Link>
                        <Link to="projects" activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500} onClick={() => setToggle(false)}>
                            <p className='cursor-pointer'>Projets</p>
                        </Link>
                        <Link to="projects" activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500} onClick={() => setToggle(false)}>
                            <a href="mailto: nssoftdev@gmail.com" className='cursor-pointer'>Me contacter</a>
                        </Link>
                    </ul>
                </motion.div>
            }
        </div>
    )
}

export default Navbar