import React from 'react'

const Footer = () => {
    return (
        <section className='bg-n8 border-t border-n7 p-8'>
            <div className='container flex flex-col items-center justify-center text-sm'>
                <div className='grid gap-8 grid-cols-4 mb-6'>
                    <a href="https://api.whatsapp.com/send?phone=22890494008">Whatsapp</a>
                    <a href="https://github.com/sebandroidev">Github</a>
                    <a href="https://www.instagram.com/devbyseb">Instagram</a>
                    <a href="mailto: nssoftdev@gmail.com">Email</a>
                </div>
                <p class=" text-n4">Copyright ©  2022 Sebastien NOGBEDJI</p>
            </div>
        </section>
    )
}

export default Footer