import React from 'react'
import { images } from '../../constants'
import { Link, animateScroll as scroll } from "react-scroll";

const Knowledge = () => {
    return (
        <section className='h-screen' id='knowledge'>
            <div className='container flex flex-col items-center justify-center h-full mx-auto'>
                <h1 className='text-4xl font-bold text-center md:text-6xl' data-aos="zoom-in" data-aos-duration="1000">Mes compétences</h1>
                <div className='flex items-center justify-center w-2/3 mx-auto mt-20 md:w-1/2' data-aos="fade-up" data-aos-duration="1000">
                    <div className='text-2xl'>
                        <p>Flutter</p>
                    </div>
                    {/* <div className='w-full h-3 rounded-full bg-border-color'>
                        <div className='w-3/4 h-full rounded-full bg-n2'>
                        </div>
                    </div> */}
                </div>
                <div className='flex items-center justify-center w-2/3 gap-10 mx-auto md:w-1/2 mt-14' data-aos="fade-up" data-aos-duration="1500">
                    <div className='text-2xl'>
                        Android Studio
                    </div>
                    {/* <div className='w-full h-3 rounded-full bg-border-color'>
                        <div className='w-1/2 h-full rounded-full bg-n2'>
                        </div>
                    </div> */}
                </div>
                <div className='flex items-center justify-center w-2/3 gap-10 mx-auto md:w-1/2 mt-14' data-aos="fade-up" data-aos-duration="2000">
                    <div className='text-2xl'>
                        SwiftUI
                    </div>
                    {/* <div className='w-full h-3 rounded-full bg-border-color'>
                        <div className='w-1/2 h-full rounded-full bg-n2'>
                        </div>
                    </div> */}
                </div>
                <div className='flex items-center justify-center w-2/3 gap-10 mx-auto md:w-1/2 mt-14' data-aos="fade-up" data-aos-duration="2500">
                    <div className='text-2xl'>
                        ReactJS
                    </div>
                    {/* <div className='w-full h-3 rounded-full bg-border-color'>
                        <div className='w-1/2 h-full rounded-full bg-n2'>
                        </div>
                    </div> */}
                </div>
                <div className='flex items-center justify-center w-2/3 gap-10 mx-auto md:w-1/2 mt-14' data-aos="fade-up" data-aos-duration="3000">
                    <div className='text-2xl'>
                        Instagram
                    </div>
                    {/* <div className='w-full h-3 rounded-full bg-border-color'>
                        <div className='w-1/3 h-full rounded-full bg-n2'>
                        </div>
                    </div> */}
                </div>



                <div className='flex items-center justify-center gap-4 mt-14'>
                    <Link to="projects" activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}>
                        <button className='border-2 discover-button'>
                            <img src={images.arrow} alt="" className='w-6 h-6 fill-black' />
                        </button>
                    </Link>
                    <p class="font-semibold text-base">Mes Projets</p>
                </div>
            </div>

        </section>
    )
}

export default Knowledge