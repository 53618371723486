import contact from "../assets/icons/contact.svg";
import profile from "../assets/images/picture.png";
import octaveLanding from "../assets/images/octave-landing.jpg";
import moloryLanding from "../assets/images/landing-page.jpg";
import miahayaZone from "../assets/images/miahayazone.jpg";
import instagramProfile from "../assets/images/instagram.jpg";
import octave from "../assets/images/octave.jpg";
import arrow from "../assets/icons/arrow.svg";
import instagram from "../assets/icons/instagram.svg";
import whatsapp from "../assets/icons/whatsapp.svg";
import twitter from "../assets/icons/twitter.svg";
import menu from "../assets/icons/menu.svg";

export default {
  contact,
  profile,
  arrow,
  instagram,
  whatsapp,
  twitter,
  menu,
  octaveLanding,
  octave,
  moloryLanding,
  miahayaZone,
  instagramProfile,
};
