import React from 'react'
import { images } from '../../constants';
import './Project.css';

const Projects = () => {
    return (
        <section className='container flex flex-col items-center mx-auto py-14' id='projects'>
            <h1 className='text-6xl font-bold text-center' data-aos="zoom-in" data-aos-duration="1000">Mes projets</h1>
            <div className='relative grid grid-cols-1 gap-8 px-6 pb-12 mt-24 md:px-0 md:grid-cols-3'>
                <article className='card_container' data-aos="fade-up" data-aos-duration="500">
                    <a href="https://play.google.com/store/apps/details?id=com.ennagroupe.octave" target={"_blank"} rel="noreferrer" >
                        <div className='image_container'>
                            <img src={images.octave} alt="mockup" className='image' />
                        </div>
                    </a>
                    <div className='content'>
                        <h3 className='font-semibold'>
                            Octave
                        </h3>
                        <p className='text-sm'>
                            Gestion de codes USSD
                        </p>
                    </div>
                </article>
                <article className='card_container' data-aos="fade-up" data-aos-duration="1000">
                    <a href="http://octave-utilities.herokuapp.com/" target={'_blank'} rel="noreferrer" >
                        <div className='image_container'>
                            <img src={images.octaveLanding} alt="mockup" className='image' />
                        </div>
                    </a>
                    <div className='content'>
                        <h3 className='font-semibold'>
                            Octave Landing Page
                        </h3>
                        <p className='text-sm'>
                            ReactJS & TailwindCSS
                        </p>
                    </div>
                </article>
                <article className='card_container' data-aos="fade-up" data-aos-duration="1500">
                    <a href="#" >
                        <div className='image_container'>
                            <img src={images.miahayaZone} alt="mockup" className='image' />
                        </div>
                    </a>
                    <div className='content'>
                        <h3 className='font-semibold'>
                            Miahaya Zone
                        </h3>
                        <p className='text-sm'>
                            Flutter
                        </p>
                    </div>
                </article>
                <article className='card_container' data-aos="fade-up" data-aos-duration="2000">
                    <a href="https://molory.com/" target={'_blank'} rel="noreferrer" >
                        <div className='image_container'>
                            <img src={images.moloryLanding} alt="mockup" className='image' />
                        </div>
                    </a>
                    <div className='content'>
                        <h3 className='font-semibold'>
                            Molory Landing Page
                        </h3>
                        <p className='text-sm'>
                            ReactJS & TailwindCSS
                        </p>
                    </div>
                </article>
                <article className='card_container' target={'_blank'} rel="noreferrer" data-aos="fade-up" data-aos-duration="2500">
                    <a href="https://www.instagram.com/devbyseb/" >
                        <div className='image_container'>
                            <img src={images.instagramProfile} alt="mockup" className='image' />
                        </div>
                    </a>
                    <div className='content'>
                        <h3 className='font-semibold'>
                            Instagram
                        </h3>
                        <p className='text-sm'>
                            Design & Publication de contenus
                        </p>
                    </div>
                </article>
            </div>

        </section>

    )
}

export default Projects